const courseAchievement = {
  // Android
  getAnId() {
    // alert('courseId: '+window.Android.getCourseId())
    let courseId = window.Android.getCourseId();
    this.getCourseInfo(courseId);
  },
  // ios
  getIosId(courseId) {
    // alert('courseId: '+courseId);
    this.getCourseInfo(courseId)
  },
  // 获取课程成绩
  getCourseInfo(courseId) {
    this.$request.getCourseGrade({courseId}).then(res => {
      if(res.data.code == 0) {
        this.courseData = res.data.data;
      }
    })
  }
}

export default courseAchievement;