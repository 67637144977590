<template>
  <div class="courseAchievement">
    <template v-if="isHaveData">
      <div class="table-area">
        <div class="table-header pub">
          <div class="h-item items">名称</div>
          <div class="h-item items">得分</div>
          <div class="h-item items">最高</div>
        </div>
        <template v-if="courseData.ruleEnableFlag">
          <div class="table-content pub" v-if="courseData.loginScoreLimit > 0">
            <div class="cont-item items">登录</div>
            <div class="cont-item items">{{String(courseData.loginScore) == 'null' ? '/' : courseData.loginScore}}</div>
            <div class="cont-item items">{{String(courseData.loginScoreLimit) == 'null' ? '/' : courseData.loginScoreLimit}}</div>
          </div>
          <div class="table-content pub" v-if="courseData.courseStudyScoreLimit > 0">
            <div class="cont-item items">课件学习</div>
            <div class="cont-item items">{{String(courseData.courseStudyScore) == 'null' ? '/' : courseData.courseStudyScore}}</div>
            <div class="cont-item items">{{String(courseData.courseStudyScoreLimit) == 'null' ? '/' : courseData.courseStudyScoreLimit}}</div>
          </div>
          <div class="table-content pub" v-if="courseData.liveStudyScoreLimit > 0">
            <div class="cont-item items">直播学习</div>
            <div class="cont-item items">{{String(courseData.liveStudyScore) == 'null' ? '/' : courseData.liveStudyScore}}</div>
            <div class="cont-item items">{{String(courseData.liveStudyScoreLimit) == 'null' ? '/' : courseData.liveStudyScoreLimit}}</div>
          </div>
          <div class="table-content pub" v-if="courseData.bookStudyScoreLimit > 0">
            <div class="cont-item items">教辅材料学习</div>
            <div class="cont-item items">{{String(courseData.bookStudyScore) == 'null' ? '/' : courseData.bookStudyScore}}</div>
            <div class="cont-item items">{{String(courseData.bookStudyScoreLimit) == 'null' ? '/' : courseData.bookStudyScoreLimit}}</div>
          </div>
          <div class="table-content pub" v-if="courseData.interactiveScoreLimit > 0">
            <div class="cont-item items">提问答疑</div>
            <div class="cont-item items">{{String(courseData.interactiveScore) == 'null' ? '/' : courseData.interactiveScore}}</div>
            <div class="cont-item items">{{String(courseData.interactiveScoreLimit) == 'null' ? '/' : courseData.interactiveScoreLimit}}</div>
          </div>
          <div class="table-content pub" v-if="courseData.homeworkScoreLimit > 0">
            <div class="cont-item items">作业</div>
            <div class="cont-item items">{{String(courseData.homeworkScore) == 'null' ? '/' : courseData.homeworkScore}}</div>
            <div class="cont-item items">{{String(courseData.homeworkScoreLimit) == 'null' ? '/' : courseData.homeworkScoreLimit}}</div>
          </div>
          <div class="table-content pub" v-if="courseData.stationScoreLimit > 0">
            <div class="cont-item items">教学点评分</div>
            <div class="cont-item items">{{String(courseData.stationScore) == 'null' ? '/' : courseData.stationScore}}</div>
            <div class="cont-item items">{{String(courseData.stationScoreLimit) == 'null' ? '/' : courseData.stationScoreLimit}}</div>
          </div>
        </template>
        <div class="table-content pub">
          <div class="cont-item items">平时成绩</div>
          <div class="cont-item items">{{String(courseData.usuallyScore) == 'null' ? '/' : courseData.usuallyScore}}</div>
          <div class="cont-item items">100</div>
        </div>
        <div class="table-content pub">
          <div class="cont-item items">期末成绩</div>
          <div class="cont-item items">{{String(courseData.endScore) == 'null' ? '/' : courseData.endScore}}</div>
          <div class="cont-item items">100</div>
        </div>
        <div class="table-content pub">
          <div class="cont-item items">补考成绩</div>
          <div class="cont-item items">{{String(courseData.supplementaryScore) == 'null' ? '/' : courseData.supplementaryScore}}</div>
          <div class="cont-item items">100</div>
        </div>
        <div class="table-content pub">
          <div class="cont-item items">线下考勤成绩</div>
          <div class="cont-item items">{{String(courseData.otherScore) == 'null' ? '/' : courseData.otherScore}}</div>
          <div class="cont-item items">100</div>
        </div>
        <div class="table-content pub">
          <div class="cont-item items">总分</div>
          <div class="cont-item items">{{String(courseData.totalScore) == 'null' ? '/' : courseData.totalScore}}</div>
          <div class="cont-item items">100</div>
        </div>
      </div>
    </template>
    <template v-else>
      <img class="noData" src="../../assets/img/noData.png" alt="">
    </template>
  </div>
</template>

<script>
import methods from './jsModule/courseAchievement'
export default {
  name: 'courseAchievement',
  data() {
    return {
      isHaveData: true,
      courseData: {},
    }
  },
  watch: {
    '$store.state.isGetToken'(val) {
      let type = this.$store.state.deviceType;
      if(val && type == 1) {
        window.webkit.messageHandlers.getData.postMessage('courseId');
        window.getIosId = this.getIosId;
      } else if(val && type == 2) {
        this.getAnId();
      }
    }
  },
  methods
}
</script>

<style lang='scss'>
  .courseAchievement {
    height: 100%;
    .table-area {
      .table-header {
        background-color: #EFEFEF;
      }
      .table-content:nth-of-type(odd) {
        background-color: #F9F9F9;
      }
      .table-content:nth-of-type(even) {
        background-color: #fff;
      }
      .pub {
        height: 50px;
        line-height: 50px;
        display: flex;
        .items {
          flex: 1;
          text-align: center;
          font-weight: 400;
          color: #333333;
        }
        .h-item {
          font-size: 16px;
        }
        .cont-item {
          font-size: 14px;
        }
      }
    }
    .noData {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 220px;
      height: 150px;
    }
  }
</style>